import Link from 'next/link'
import Image from 'next/image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import style from './Shared.module.css'


export default function MyFooter() {

    const year = new Date().getFullYear()
    return (<div>
        <Container>
            <Row >
                <Col className='text-center' >
                    <div className={style.hrTopFooter} />
                </Col>
            </Row>
        </Container>

        <footer className={style.pageFooter}>
            <Container>
                <Row >
                    <Col xs={12} sm={4} className='text-center' >
                        <h2 className={style.footerNavHeading}>Information</h2>
                        <Image src='/images/ngc11.gif'
                            alt="NGC"
                            width={75}
                            height={35}
                        />
                        <ul className={style.footerNavList}>
                            <li className={style.footerNavItem}>
                                <Link href='/aboutUs' replace passHref>
                                    About Us
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/shippingReturnPolicy' replace passHref>
                                    Shipping & Return
                                </Link></li>
                            <li className={style.footerNavItem}>
                                <Link href='/privacyPolicy' replace passHref >
                                    Privacy Policy
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/employment' replace passHref >
                                    Job Opportunities
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={4} className='text-center' >
                        <h2 className={style.footerNavHeading}>Categories</h2>
                        <ul className={style.footerNavList}>
                            <li className={style.footerNavItem}>
                                <Link href='/worldCoins' passHref >
                                    World Coins
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/UScoins' passHref >
                                    US Coins
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/USpaperMoney' passHref >
                                    US Paper Money
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/paperMoney' passHref >
                                    Paper Money
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/ancientCoins' passHref >
                                    Ancient & Medieval
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/medalsTokens' passHref >
                                    Exonumic
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/newArrivals' passHref >
                                    New Arrivals
                                </Link>
                            </li>
                            <li className={style.footerNavItem}>
                                <Link href='/archiveCoins' passHref >
                                    Archive of the Sold Items
                                </Link></li>
                        </ul>
                    </Col>
                    <Col xs={12} sm={4} className='text-center' >
                        <h2 className={style.footerNavHeading}>Contact</h2>
                        <address>
                            <ul className={style.footerNavList}>
                                <li className={style.footerNavItem}>
                                    <Link href='/contact' passHref >
                                        Contact Us
                                    </Link></li>
                                <li className={style.footerNavItem}>FLGCE</li>
                                <li className={style.footerNavItem}>P.O Box 38</li>
                                <li className={style.footerNavItem}>Lutz, Fl</li>
                                <li className={style.footerNavItem}>
                                    <a href="tel:+17276562974">+1-727-656-2974</a>
                                </li>
                            </ul>
                        </address>

                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='justify-content-center'>
                    <Col className='text-center' xs={12} sm={6}>
                        <div className={style.hrBottomFooter} />
                        <div className={style.footerCopyright}>&copy;{year} FL Gold Coin Exchange, inc. All rights reserved</div>
                        <address className={style.footerNavItem}><a href="mailto:coinomania@gmail.com">coinomania@gmail.com</a></address>
                    </Col>
                </Row>
            </Container>
        </footer>
    </div>
    )
}